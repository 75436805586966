import React from 'react'
import WhyCollektoImg from '../img/collekto/WhyCollekto_BgImage.jpg'

function WhyCollekto({ Data }) {

    return (
        <section className="whyCollekto" style={{ background: `url(${WhyCollektoImg}) top no-repeat`, backgroundSize: 'cover' }}>
            <div className="container" data-aos="fade-up">
                <header className="section-header text-center my-4">
                    <p>Why Collekto ?</p>
                </header>

                <div className="row d-flex justify-content-center" data-aos="fade-up" data-aos-delay="50">
                    {
                        Data.map(collek => {
                            return (
                                <div key={collek.id} className="col-lg-4 col-md-5 col-sm-6" >
                                    <div className="box m-3">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="d-flex align-items-center justify-content-center image-box" >
                                                    <img src={require('../img/' + collek.image).default} alt="" />

                                                </div>
                                            </div>
                                            <p className="my-3">{collek.description}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default WhyCollekto
