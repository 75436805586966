import React from 'react'
import HeroImg from '../img/collekto/BannerImage.png';
function Hero({handleModal}) {
    return (
        <div>
            <section className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex flex-column justify-content-center">
                            <h1 className="title" data-aos="fade-up">Collekto</h1>
                            <h1 data-aos="fade-up">is a fintech company specialising in retail debt collections</h1>
                            <h2 className="mt-lg-5 mt-md-4 mb-3" data-aos="fade-up" data-aos-delay="400">We offer a complete 'Product+Service' solution to the lending institutions. We provide service across India through our aggregator model of quality service providers backed by an expert in-house team relying heavily on data science, analytics and past experience.</h2>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div className="text-center text-lg-start">
                                    <a className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center" onClick={handleModal}>
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={HeroImg} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Hero
