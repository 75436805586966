import React from 'react'

function Offerings({ Data }) {
    return (
        <section className="offerings">
            <div className="fluid-container" data-aos="fade-up" data-aos-delay="50">

                <header className="section-header text-center my-5">
                    <p>Our Offerings</p>
                </header>

                    <div className="row d-flex justify-content-around align-self-center" >

                        {
                            Data.map(offers => {
                                return (
                                    <div key={offers.id} className="col-lg-3 col-md-8 col-sm-10 m-3">
                                        <div className="box row d-flex justify-content-center text-center">
                                            <div className="image col-12 text-center">
                                                <img src={require('../img/' + offers.image).default} className="img" alt="" />
                                            </div>
                                            <p className="title my-3">{offers.name}</p>
                                            <p className="description my-3">{offers.description}</p>
                                        </div>
                                    </div>

                                );
                            })
                        }


                    </div>

            </div>
        </section>
    )
}

export default Offerings
