import React, { useState } from 'react';
import logo from '../img/collekto/logo.png';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,

} from 'reactstrap';

function Navigation({ handleModal}) {
    const [isOpen, setIsOpen] = useState(false);


    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <header id="header" className={`header header-scrolled`}>
                <Navbar className="container-fluid container-xl d-flex align-items-center justify-content-between" expand="md">

                    <NavbarBrand href="/" className="logo d-flex align-items-center">
                        <img src={logo} alt="" />
                    </NavbarBrand>

                    <NavbarToggler className="bi bi-list-nested" onClick={toggle} />

                    <Collapse isOpen={isOpen} navbar className="justify-content-end">
                        <Nav id="navbar" className="navbar" navbar>
                            <NavItem>
                                <NavLink className="nav-link scrollto" href="/lender">Lending Institution</NavLink>
                                
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link scrollto" href="/agency">Collection Agency</NavLink>
                                
                            </NavItem>
                            <NavItem>
                            <NavLink className="nav-link scrollto" href="/#team">About</NavLink>
                            </NavItem>

                            <NavItem>
                            <NavLink className="getstarted scrollto" onClick={handleModal}>Demo</NavLink>

                            </NavItem>
                        </Nav>

                    </Collapse>


                </Navbar>
            </header>
        </div >
    )
}

export default Navigation
