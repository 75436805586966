import React, { useState } from 'react'
import Navigation from '../Navigation'
import ServiceHero from '../ServiceHero'
import {Service as Data} from '../Data'
import Footer from '../Footer'
import Contact from '../Contact'
function Lender() {
    
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
            <Navigation handleModal={handleOpen} topFix={true}/>
            <Contact open={open} handleClose={handleClose} />

            <ServiceHero Data={Data.Lender}/>
            <Footer />
        </div>
    )
}

export default Lender
