import React from 'react'
import { ClientsLeft, ClientsRight } from './Data'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {isMobile} from "react-device-detect";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

function Clients({ addRef },props) {

    
    const CustomRightArrow = ({ ...rest }) => {
        return <button/>;
    };

    const clientLeft = ClientsLeft.map(client => {
        return (
            <div className="slide row d-flex align-items-center justify-content-center" key={client.id}>
                <div className=" col-lg-6 col-md-6 col-sm-8">
                    <div className="row d-flex align-items-center justify-content-center text-center">
                        <img src={require('../img/' + client.image).default} className="img-fluid  my-auto" alt="" />
                    </div>

                </div>
            </div>
        )
    })

    const clientRight = ClientsRight.map(client => {
        return (
            <div className="slide row d-flex align-items-center justify-content-center" key={client.id}>
                <div className=" col-lg-6 col-md-6 col-sm-8 text-center">
                    <div className="row d-flex align-items-center">
                        <img src={require('../img/' + client.image).default} className="img-fluid  my-auto" alt="" />
                    </div>

                </div>
            </div>
        )
    })


    return (
        <div>
            <section ref={addRef} id={'clients'} className="clients bg-light">

                <div className="container" data-aos="fade-up">

                    <header className="section-header text-center my-4">
                        <p>Our Clients</p>
                    </header>
                    <div className="row d-flex align-items-center justify-content-center my-4">
                        <Carousel
                            keyBoardControl={true}
                            infinite={true}
                            draggable={true}
                            showDots={isMobile}
                            autoPlay={isMobile}
                            deviceType={props.deviceType}
                            removeArrowOnDeviceType={["mobile"]}
                            customRightArrow={<CustomRightArrow />}
                            responsive={responsive}
                        >
                            {clientLeft}
                        </Carousel>
                    </div>


                    <div className="row d-flex align-items-center justify-content-center my-4">
                        <Carousel
                            keyBoardControl={true}
                            infinite={true}
                            draggable={true}
                            showDots={isMobile}
                            autoPlay={isMobile}
                            deviceType={props.deviceType}
                            removeArrowOnDeviceType={["mobile"]}
                            customLeftArrow={<CustomRightArrow />}
                            responsive={responsive}
                        >
                            {clientRight}
                        </Carousel>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Clients

