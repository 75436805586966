import React, { useRef,useState,useLayoutEffect } from 'react'

import Footer from '../Footer'
import Hero from '../Hero'
import Navigation from '../Navigation'
import Service from '../Service'
import Testimonials from '../Testimonials'
import Clients from '../Clients'
import Contact from '../Contact'
import { useLocation } from 'react-router'
import Team from '../Team'
import TechModel from '../TechModel'

function Home() {
    const [open, setOpen] = useState(false);

    const aboutSection = useRef(null);
    const serviceSection = useRef(null);
    const testimonialSection = useRef(null);
    const clientSection = useRef(null);
    const location = useLocation();

    useLayoutEffect(() => {
        if(location.hash === '#testimonials') goToTestimonialSection();
        if(location.hash === '#services') goToServiceSection();
        if(location.hash === '#clients') goToClientSection();
        if(location.hash === '#team') goToAboutSection();
    });

    const goToAboutSection = ()=>{
        window.scrollTo(0,aboutSection.current.offsetTop-30)

    }

    const goToTestimonialSection = ()=>{
        window.scrollTo(0,testimonialSection.current.offsetTop)
    }

    const goToClientSection = ()=>{
        window.scrollTo(0,clientSection.current.offsetTop)
    }

    const goToServiceSection = ()=>{
        window.scrollTo(0,serviceSection.current.offsetTop-30)
    } 


    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <React.Fragment>
            <Navigation handleModal={handleOpen} scrollService={goToServiceSection} topFix={true}/>
            <Contact open={open} handleClose={handleClose} />
            <Hero handleModal={handleOpen}/>
            <TechModel />
            <Service addRef={serviceSection}/>
            <Testimonials addRef={testimonialSection}/>
            <Clients addRef={clientSection}/>
            <Team addRef={aboutSection} />
            <Footer />
        </React.Fragment>
    )
}

export default Home
