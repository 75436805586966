import React from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import contactImg from '../img/collekto/contact.png'
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

}));

function Contact({ open, handleClose }) {

    const classes = useStyles();
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { register, handleSubmit, formState: { errors },reset} = useForm();
    const onSubmit = (data,e) => {
        console.log(data);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('http://localhost:8000/', requestOptions)
            .then(response => console.log(response));
        reset()
    }
    
   
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className={classes.modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className="contact-form col-lg-8 col-md-8 col-sm-10">
                        <div className="wrapper">
                            <div className="d-flex flex-row justify-content-between pt-md-5 pt-4 px-5 mb-4">
                                <h3>Get in touch with us</h3>
                                <i className="bi bi-x close-icon" onClick={handleClose}></i>
                            </div>
                            <div className="modal-body row d-flex justify-content-center">
                                <div className="col-md-9 col-lg-6 col-sm-10">
                                    {/* <div className="contact-wrap"> */}

                                        <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">First Name</label>
                                                        
                                                        <input type="text" className="form-control" 
                                                        {...register("firstName", 
                                                            { 
                                                                required: "*required", 
                                                                maxLength: {value:20,message:'*Input length exceed'} 
                                                                })
                                                        } placeholder="eg. Jhon" />
                                                        
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="firstName"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Last Name</label>

                                                        <input type="text" className="form-control" 
                                                        {...register("lastName", 
                                                            {   required: "*required", 
                                                                maxLength: {value:20,message:'*Input length exceed'}
                                                            })}
                                                         placeholder="eg. Doe" />

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="lastName"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        /></div>
                                                </div>
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Email Address</label>

                                                        <input type="text" className="form-control" 
                                                        {...register("email", 
                                                            {   required: "*required", 
                                                                pattern: {value:re,message:'*Invalid email'}
                                                            })}
                                                         placeholder="eg. sample@gmail.com" />

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="email"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Phone</label>
                                                        
                                                        <input type="text" className="form-control" 
                                                        {...register("contact", 
                                                            {   required: "*required",
                                                                pattern:{value:/^[0-9]+$/, message:"*Not a number"},
                                                                maxLength: {value:10,message:'*Invalid Phone'},
                                                            })}
                                                         placeholder="eg. xx-xxxx-xxxx" />

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="contact"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Company</label>

                                                        <input type="text" className="form-control" 
                                                        {...register("company", 
                                                            {   required: "*required",
                                                            })}
                                                         placeholder="eg. Collekto" />

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="company"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-10 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Role</label>

                                                        <input type="text" className="form-control" 
                                                        {...register("role", 
                                                            {   required: "*required",
                                                            })}
                                                         placeholder="eg. Agency/Lender" />

                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="role"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-12 my-3">
                                                    <div className="form-group">
                                                        <label className="label">Message</label>

                                                        <textarea className="form-control"
                                                        {...register("message", 
                                                            { required: "*required" })
                                                        } cols="30" rows="4" placeholder="Message"></textarea>


                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="message"
                                                            render={({ message }) => <p className="error">{message}</p>}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-12 my-3">
                                                    <div className="form-group">
                                                        <input type="submit" value="Send Message" className="btn btn-primary" />
                                                        <div className="submitting"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    {/* </div> */}
                                </div>
                                <div className="col-lg-5 d-flex align-items-center">
                                    
                                    <img src={contactImg} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default Contact
