import React from 'react'
import Logo from '../img/collekto/logo.png'
import PrivacyPdf from "../img/collekto/Privacy Policy.pdf";

function Footer() {


    const privacyClick = () => {
        window.open(PrivacyPdf);
    }

    return (
        <div>
            <footer className="py-5">
                <div className="container">
                    <div className="row d-flex justify-contents-center">
                        <div className="col-10 col-md">
                            <ul className="list-unstyled text-small">
                                <li><img src={Logo} className="img-fluid" alt="" /></li>
                                <li className="my-3">
                                    <small>Pernimius Financial Technologies Pvt ltd.</small>

                                </li>
                            </ul>

                        </div>
                        <div className="col-6 col-md">
                            <h5>Features</h5>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted" href="/#services">Services</a></li>
                                <li><a className="text-muted" href="/#clients">Clients</a></li>
                                <li><a className="text-muted" href="/#testimonials">Testimonials</a></li>
                            </ul>
                        </div>

                        <div className="col-6 col-md">
                            <h5>About</h5>
                            <ul className="list-unstyled text-small">
                                <li><a className="text-muted" href="/#team">Team</a></li>
                                <li><a className="text-muted" href="/" onClick={privacyClick}>Privacy Policy</a></li>
                            </ul>
                        </div>

                        <div className="col-6 col-md">
                            <h5>Get in Touch</h5>
                            <div className="social text-center">
                                {/* <a href="/"><i className="bi bi-twitter"></i></a>
                                <a href="/"><i className="bi bi-facebook"></i></a>
                                <a href="/"><i className="bi bi-instagram"></i></a> */}
                                <a href="https://www.linkedin.com/company/collekto-ai/"><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>


                    </div>
                </div>

            </footer>
        </div>
    )
}

export default Footer
