import React from 'react'
import Offerings from './Offerings';
import WhyCollekto from './WhyCollekto';
import Features from './Features'

function ServiceHero({ Data }) {
    return (
        <div>
            <section className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex flex-column justify-content-center">
                            <h1 className="title" data-aos="fade-up">{Data.title1}</h1>
                            <h1 className="title" data-aos="fade-up">{Data.title2}</h1>
                            <h2 className="mt-lg-5 mt-md-4 mb-3" data-aos="fade-up" data-aos-delay="400">{Data.description}</h2>
                        </div>
                        <div className="col-lg-5 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={require('../img/'+ Data.serviceImg ).default} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

            </section>

            <Offerings Data={Data.Offerings} />

            <WhyCollekto Data={Data.whyCollekto} />

            <Features Data={Data.productFeatures} FeatureImg={Data.featureImg} />

        </div>

    )
}

export default ServiceHero
