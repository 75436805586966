import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 486, min: 0 },
        items: 1
    }
};
function Features({ Data, FeatureImg }) {


    const slides = Data.map((item) => {
        return (

            <div className="row carousel d-flex justify-content-center mx-lg-1 mx-5">
                <div className="slide col-lg-10 col-md-10 col-sm-8 text-center">
                    <div className="row  d-flex justify-content-start">
                        <div className="image-box">
                            <div className="row d-flex justify-content-center align-items-center">
                                <img src={require('../img/' + item.image).default} className="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                    <div className="row d-flex justify-content-center align-items-center content-box pt-3">
                        <p>{item.description}</p>
                    </div>
                </div>
            </div>

        );
    });
    return (
        <section className="features">
            <div className="fluid-container" data-aos="fade-up" data-aos-delay="50">
                <header className="section-header text-center my-4">
                    <p>Product Features</p>
                </header>
                <div className="row d-flex align-items-center justify-content-center my-4">
                    <div className="col-lg-11 col-md-11 col-sm-11 ">
                        <Carousel 
                            swipeable={true} 
                            draggable={true} 
                            removeArrowOnDeviceType={["desktop","tablet", "mobile"]} 
                            showDots={true} 
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            responsive={responsive}>
                            {slides}
                        </Carousel>
                    </div>
                </div>
                <div className="row d-flex justify-content-center my-4">
                        <div className="banner row col-lg-6 col-md-6">
                            <img src={require('../img/'+FeatureImg).default} className="img-fluid" alt="" />
                        </div>
                </div>
            </div>
        </section>
    )
}

export default Features
