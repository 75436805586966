import React from 'react'
import { Team as TeamData } from './Data';
import TeamBg from '../img/bg/OurTeam_BgDesign.png'

function Team({ addRef }) {
    return (
        <section ref={addRef} id="team" className="team-new">

            <div className="container" data-aos="fade-up">

                <header className="section-header  text-center my-4">
                    <p>Our Team</p>
                </header>

            </div>

            <div className="fluid-container" style={{ background: `url(${TeamBg}) top no-repeat`, backgroundSize: 'contain' }} data-aos="fade-up" data-aos-delay="100">


                {
                    TeamData.map(member => {
                        if (member.id % 2 !== 0) {
                            return (
                                <div className="row d-flex justify-content-between">
                                    <div className="img-box right" style={{ background: `url(${require('../img/' + member.image).default}) top no-repeat`, backgroundSize: 'cover' }}>
                                        {/* <div className="arc right"></div> */}
                                    </div>
                                    <div className="content">
                                        <div className="content-left">
                                            <h2 className="m-3">{member.name}</h2>
                                            
                                            <h3 className="m-3"><a className="mx-3" href={member.linkedin}><i className="bi bi-linkedin"></i></a> {member.designation}</h3>
                                            <p className="m-3">{member.description}</p>
                                        </div>
                                        <hr className="hr-right my-4" />

                                    </div>

                                </div>
                            );
                        } else {
                            return (
                                <div className="row d-flex flex-row-reverse justify-content-between">
                                    <div className="img-box left" style={{ background: `url(${require('../img/' + member.image).default}) top no-repeat`, backgroundSize: 'cover' }}>
                                        {/* <div className="arc left"></div> */}
                                    </div>
                                    <div className="content">
                                        <div className="content-right">
                                            <h2 className="m-3">{member.name}</h2>
                                            
                                            <h3 className="m-3">{member.designation} <a className="mx-3" href={member.linkedin}><i className="bi bi-linkedin"></i></a></h3>
                                            <p className="m-3">{member.description}</p>
                                        </div>
                                        <hr className="hr-left my-4" />

                                    </div>

                                </div>

                            );
                        }
                    })
                }


            </div>

        </section>
    )
}

export default Team
