import React from 'react'
import ServicesImg from '../img/collekto/OurServices_Image.png'
import ServicesBg from '../img/bg/OurServices_BGDesign.png'

function Service({addRef}) {
    return (
        <main id="main">
            <section ref={addRef} id={'services'} className="values bg-light" >

                <div className="container" data-aos="fade-up">

                    <header className="section-header text-center">
                        <p>Are you a Lending Institution or a Collection Agency?</p>
                    </header>

                </div>
                <div className="fluid-container">
                <div className="box row d-flex justify-content-center my-5" style={{ background: `url(${ServicesBg}) top no-repeat`, backgroundSize: 'contain' }} data-aos="fade-up" data-aos-delay="100">
                        <div className="value-1 col-lg-3 col-md-4 col-sm-4 align-self-start text-end mx-4">
                            <a href="/lender"><h2 className="title-1">Lending Institution</h2></a>
                            <p>We offer a complete 'Product+Service' solution to the lending institutions.</p>
                        </div>

                        <div className="value-img col-lg-2 col-md-3 col-sm-4 align-self-center text-center mx-4" >
                            <img className="img-fluid" src={ServicesImg} alt=""/>
                        </div>

                        <div className="value-2 col-lg-3 col-md-4 col-sm-4 align-self-end text-start mx-4">
                            <a href="/agency"><h2 className="title-2">Collection Agency</h2></a>                            
                            <p>Redefining the way collections is done in India by empowering the Collection Agencies with new age Technology.</p>
                        </div>
                    </div>

                </div>


            </section>
        </main>
    )
}

export default Service
