import React from 'react'
import modelImg from '../img/collekto/Aggregator&TechModel.png'
function TechModel() {
    return (
        <div>
            <section>
                <div className="container" data-aos="fade-up" data-aos-delay="50">
                        <div className="row d-flex justify-content-center">
                        <div className="col-md-8">

                            <img src={modelImg} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TechModel
