import { useEffect } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Home from './Components/Pages/Home';
import Lender from './Components/Pages/Lender';
import Agency from './Components/Pages/Agency';
import AOS from 'aos'

import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/lender" component={Lender} />
        <Route path="/agency" component={Agency} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
