import React from 'react'
import { Testimonials as TestimonialData } from './Data'
import TestimonialImg from '../img/collekto/TestimonialImage.png'
import TestimonialBg from '../img/bg/TestimonialImage_BgDesign.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function Testimonials({ addRef }) {

   
    
    const slides = TestimonialData.map((testimonial) => {
        return (
            <div className="row carousel d-flex justify-content-center">
                <div className="slide col-md-8 text-center">
                    <div className="row  d-flex justify-content-center">

                        <div className="image-box">
                            <div className="row d-flex justify-content-center align-items-center">
                                <img src={require('../img/' + testimonial.image).default} className="img-fluid my-auto" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="text-center content-box">
                        <h3>{testimonial.authorName}</h3>
                        <h4>{testimonial.authorPost}</h4>
                        <p>{testimonial.description}</p>
                    </div>
                </div>
            </div>
        );
    });


    return (
        <section ref={addRef} id={'testimonials'} className="testimonials">


            <div className="fluid-container" style={{ background: `url(${TestimonialBg}) top no-repeat`, backgroundSize: 'contain' }}>

                <header className="section-header text-center my-4" data-aos="fade-up" >
                    <p>What they are saying about us ?</p>
                </header>

                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <Carousel 
                        keyBoardControl={true}
                        responsive={responsive}>
                        {slides}
                    </Carousel>
                    <div className="row d-flex justify-content-center my-4">
                        <div className="row col-lg-6 col-md-6">
                            <img src={TestimonialImg} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

            </div>



        </section>

    )
}

export default Testimonials
