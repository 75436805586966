export const Testimonials = [
  {
    id: 1,
    image: "collekto/testimonials/test-1.png",
    description:
      "I thank everyone of you for the constant hard work and dedication in the month of October 2020. I will take this opportunity to thank you for being you. Thank you for being a part of our team. Keep up the good work",
    authorName: "Guruprasad Shetty",
    authorPost: "Collection Manager-Digital Mumbai",
  },
  {
    id: 2,
    image: "collekto/testimonials/test-2.png",
    description:
      "This is to place on record the commendable work done by team Collekto to enhance performance and collection efficiency more specifically during the covid period. Collekto team's commitment and hard work ensured a month increase in collection efficiency from April'20 to reaching an all time high mark of 97% in bkt X in Nov'20. Hat's off to the team for such a remarkable feat. Collekto with the futuristic approach on technology and integrated collection platform will make a key differentiator in the collection ecosystem for sure. Here's wishing them all the very best.",
    authorName: "Koustubh Bhagat ",
    authorPost: "Head-Collections & Legal",
  },
  {
    id: 3,
    image: "collekto/testimonials/test-3.png",
    description:
      "Collekto has been associated with us since February 2019. The key factors or the reasons we associated were the potential and promising business values that Collekto brought to the association, which we valued as key . Although the start was slow, we have seen how business evolved over a period of time, right from 7th position in overall ranking to no 2 among the associates at national level. The turn around has been phenomenal and with high value based support. The technical support, apart from good leadership has been key factor in this transition. Pan India presence and stability among at the team and vision of leaders make you stand apart. I wish the team very best and look forward for a great future and business association . As I understand Collekto has been venturing into multiple portfolios, without driving off the attentions and focus, will surely marvel a great journey ahead.",
    authorName: "Rishikesh Pillai",
    authorPost: "Head- Collections, CashE",
  },
];

export const Team = [
  {
    id: "1",
    name: "Bipul Naithani",
    linkedin: "https://www.linkedin.com/in/bipul-c-naithani-27241117",
    twitter: "",
    facebook: "",
    gmail: "",
    image: "collekto/team/member.png",
    designation: "Cofounder & CEO",
    description:
      "Expertise in Collections & Vintage Recovery, Risk and P/L Management. Over 27 years of experience with companies like Kotak Mahindra Bank, ICICI and KMFL. Handled collection of AUM of Rs. 20,000 Crores. Successfully managed to collect Rs.300 crores out of a write-off pool of  INR 485 crores in Kotak Mahindra Bank. \n           Planned, designed & implemented collection strategy, techniques & procedures for secured and unsecured products. Drove critical merger of secured portfolios such as Anagram & ICICI Merger, Barclays and Ing Vyasa with Kotak bank",
  },
  {
    id: "2",
    name: "Gurjot Singh",
    linkedin: "https://www.linkedin.com/in/gurjot-singh-frm-6aa6067",
    twitter: "",
    facebook: "",
    gmail: "",
    image: "collekto/team/member-2.png",
    designation: "Cofounder & CFO",
    description:
      "Expertise in managing balance sheet funding, structuring deals, maintaining banking relationship and financial risk management. More than 13 years of experience with companies like IIFL and Reliance Capital with focus on fund raising and resource mobilization, reducing cost of funds, establishing and maintaining relationship with lenders in NBFC sector. Successfully raised debt to the tune of Rs.34000 crores and has an active relationship with more than 40 Financial Institutions. \n           Qualification: MBA (Finance) - NMIMS, Mumbai and Financial Risk Management (GARP, USA) Qualified to represent India at Ironman 70.3 World Championship in New Zealand (Triathlon: 2 km Swim + 90km Cycling + 21.1 km Running)",
  },
  // {
  //     id: '3',
  //     name: 'Senthil Velu',
  //     linkedin: 'https://www.linkedin.com/in/senthil-nathan-velu-641980a',
  //     twitter:'',
  //     facebook:'',
  //     gmail:'',
  //     image: "collekto/team/member-3.png",
  //     designation: 'CTO',
  //     description:'Has expertise in designing and implementing solutions across a range of industry verticals including financial services, public sector and Internet of Things (IoT). More than 20 years of experience across companies like ARM Inc, GT Logistics, OmniPhy Inc and Artisan Inc. He is a creative technology and product leader specializing in AI and Data Science.  \n           He is passionate about Deep Learning and bringing Machine Learning into everyday corporate India by leveraging technologies that enable it. In the past few years he has designed large scale, high volume deep learning programs in the financial services and public sector domain (state government transport authority). He has expertise in building scalable and efficient data driven products for recommendations, NLP driven hyper-personalized servicing and marketing, fraud/anomaly detection and BI capabilities. \n            He has graduated with a MBA in Finance from Indian Institute of Management, IIM (T) in 2015. He was a (Ph.D.) Scholar at Carnegie Mellon University, Pittsburgh, majoring in Computer Science in 2004 and graduated with a M.S. in Computer Engineering from North Carolina State University, Raleigh in 2002.'
  // }
];

export const ClientsLeft = [
  {
    id: "1",
    image: "collekto/clients/client-1.png",
  },
  {
    id: "2",
    image: "collekto/clients/client-2.png",
  },
  {
    id: "3",
    image: "collekto/clients/client-3.png",
  },
  //   {
  //     id: "4",
  //     image: "collekto/clients/client-4.png",
  //   },
  {
    id: "5",
    image: "collekto/clients/client-5.png",
  },
];

export const ClientsRight = [
  {
    id: "6",
    image: "collekto/clients/client-6.png",
  },
  {
    id: "7",
    image: "collekto/clients/client-7.png",
  },
  {
    id: "8",
    image: "collekto/clients/client-8.png",
  },
  {
    id: "9",
    image: "collekto/clients/client-9.png",
  },
  {
    id: "10",
    image: "collekto/clients/client-10.png",
  },
];

export const Service = {
  Lender: {
    title1: "Lending",
    title2: "Institution",
    serviceImg: "collekto/lender/BannerImage.png",
    description:
      "We offer a complete Product+Service solution to the lending institutions.",
    featureImg: "collekto/lender/ProductFeatures_Images.png",
    Offerings: [
      {
        id: "1",
        name: "Product (Platform access)",
        description: "Access to Collekto’s Integrated Platform",
        image: "collekto/lender/offer/Product Platform access -Image.png",
      },
      {
        id: "2",
        description:
          "We offer entire suite of  Collection services comprising of Telecalling ,Field and Litigation",
        name: "Collection Management Service",
        image: "collekto/lender/offer/Collection Management Service-Image.png",
      },
      {
        id: "3",
        description:
          "We assist in acquisition of delinquent portfolios by offering fair price at a quick turnaround time",
        name: "Delinquent Portfolio Acquisition",
        image:
          "collekto/lender/offer/Delinquent Portfolio Acquisition-Image.png",
      },
    ],
    whyCollekto: [
      {
        id: "1",
        image: "collekto/lender/why/icon-1.png",
        description:
          "Access to Collekto’s network of Agencies of more than 100 across 30+ locations.",
        color: "rgb(29, 130, 216, 0.3)",
        show: false,
      },
      {
        id: "2",
        image: "collekto/lender/why/icon-2.png",
        description:
          "Access to Realtime Dashboards to monitor Collection activity at a macro level.",
        color: "rgb(255, 189, 99, 0.3)",
        show: false,
      },
      {
        id: "3",
        image: "collekto/lender/why/icon-3.png",
        description:
          "Access to Account level activity to monitor at a micro level.",
        color: "rgb(78, 203, 222, 0.3)",
        show: false,
      },
      {
        id: "4",
        image: "collekto/lender/why/icon-4.png",
        description:
          "Improved Collection efficiency due to full suite product availability with partners.",
        color: "rgb(140, 103, 223, 0.3)",
        show: false,
      },
      {
        id: "5",
        image: "collekto/lender/why/icon-5.png",
        description: `Plug and Play Tech product to streamline the entire data flow across multiple collection silos`,
        color: "rgb(0, 189, 145, 0.3)",
        show: false,
      },
      {
        id: "6",
        image: "collekto/lender/why/icon-6.png",
        description: "Reduction in Overheads.",
        color: "rgb(153, 51, 102, 0.3)",
        show: false,
      },
      {
        id: "7",
        image: "collekto/lender/why/icon-7.png",
        description:
          "Complete transparency of the entire collections process – From Allocation to payment.",
        color: "rgb(0, 139, 139, 0.3)",
        show: false,
      },
      {
        id: "8",
        image: "collekto/lender/why/icon-8.png",
        description: "Access to Data insights on the allocated portfolio.",
        color: "rgb(121, 26, 156, 0.3)",
        show: false,
      },
      {
        id: "9",
        image: "collekto/lender/why/icon-9.png",
        description:
          "Assistance in formulating and executing Collection Strategies based on data insights.",
        color: "rgb(29, 130, 216, 0.3)",
        show: false,
      },

      {
        id: "10",
        image: "collekto/lender/why/icon-10.png",
        description:
          "Access to Data insights which act as a feedback loop to the underwriting models.",
        color: "rgb(50, 154, 50, 0.3)",
        show: false,
      },
    ],
    productFeatures: [
      {
        id: "1",
        image: "collekto/features/crm.png",
        description: "CRM",
      },
      {
        id: "2",
        image: "collekto/features/Group 3049.png",
        description: "Unlimited Calling",
      },
      {
        id: "3",
        image:
          "collekto/features/Customised Calling Campaigns including Voice Broadcast facility.png",
        description:
          "Customized Calling Campaigns including Voice Broadcast facility",
      },
      {
        id: "4",
        image: "collekto/features/Call Recording Facility.png",
        description: "Call recording facility",
      },
      {
        id: "5",
        image: "collekto/features/Realtime Dashboard Access.png",
        description: "Realtime Dashboard Access",
      },
      {
        id: "6",
        image:
          "collekto/features/Access to Data Insights Module for Portfolio specific insights.png",
        description:
          "Access to Data Insights Module for Portfolio specific insights",
      },
      {
        id: "7",
        image: "collekto/features/message.png",
        description: "Integrated Dialer for Manual and Predictive dialling",
      },
    ],
  },
  Agency: {
    title1: "Collection",
    title2: "Agencies",
    serviceImg: "collekto/agency/BannerImage.png",
    description:
      "Redefining the way collections is done in India by empowering the Collection Agencies with new age Technology.",
    featureImg: "collekto/agency/ProductFeatures_Images.png",
    Offerings: [
      {
        id: "1",
        name: "Product (Platform access)",
        description: "Access to Collekto’s Integrated Platform",
        image: "collekto/agency/offer/Product Platform access -Image.png",
      },
      {
        id: "2",
        name: "Asset Allocation",
        description:
          "Receive higher allocation of accounts from Country’s best lenders across DPDs and product segments",
        image: "collekto/agency/offer/Asset Allocation-Image.png",
      },
    ],
    whyCollekto: [
      {
        id: "1",
        image: "collekto/agency/why/icon-1.png",
        description:
          "Additional Business: Higher allocation of accounts leading to higher income",
        color: "rgb(29, 130, 216, 0.3)",
        show: false,
      },
      {
        id: "2",
        image: "collekto/agency/why/icon-2.png",
        description: "Reduction in Overheads",
        color: "rgb(255, 189, 99, 0.3)",
        show: false,
      },
      {
        id: "3",
        image: "collekto/agency/why/icon-3.png",
        description: "Access to Collekto’s Integrated Collection Platform",
        color: "rgb(78, 203, 222, 0.3)",
        show: false,
      },
      {
        id: "4",
        image: "collekto/agency/why/icon-4.png",
        description:
          "Complete transparency of the entire collections process – from Allocation to payment.",
        color: "rgb(140, 103, 223, 0.3)",
        show: false,
      },
      {
        id: "5",
        image: "collekto/agency/why/icon-5.png",
        description:
          "Improved Collection Efficiency due to full suite product availability.",
        color: "rgb(0, 189, 145, 0.3)",
        show: false,
      },
      {
        id: "6",
        image: "collekto/agency/why/icon-6.png",
        description:
          "Assistance in formulating and executing Collection Strategies based on data insights",
        color: "rgb(199, 51, 44, 0.3)",
        show: false,
      },
    ],
    productFeatures: [
      {
        id: "1",
        image: "collekto/features/crm.png",
        description: "CRM",
      },
      {
        id: "2",
        image: "collekto/features/Group 3049.png",
        description: "Unlimited Calling",
      },
      {
        id: "3",
        image: "collekto/features/message.png",
        description: "Integrated Dialer for Manual and Predictive dialling",
      },
      {
        id: "4",
        image: "collekto/features/Realtime Dashboard Access.png",
        description: "Realtime Dashboard Access",
      },
      {
        id: "5",
        image: "collekto/features/Call Recording Facility.png",
        description: "Call Recording Facility",
      },
      {
        id: "6",
        image:
          "collekto/features/Customised Calling Campaigns including Voice Broadcast facility.png",
        description:
          "Customized Calling Campaigns including Voice Broadcast facility",
      },
    ],
  },
};
